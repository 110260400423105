h1, h2 {
  font-family: 'Roboto Slab', serif;
}

.readableALink {
  a {
    color: blue;
    text-decoration: underline;
  }

  a:hover, a:active {
    text-decoration: none;
    color: darkred;
  }

  a:visited {
    color: darkslateblue;
  }
}

body {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (max-width: 960px) {
  body {
    padding-top: 0;
  }

  .hero-unit h1 {
    margin-bottom: 11px;
  }

  .hero-unit h1 small {
    font-size: 1em;
  }

  .hero-unit {
    padding: 3px;
  }

  .no-mobile {
    display: none;
  }
}

@media (max-width: 576px) {
  .btn {
    margin-bottom: 5px;
  }
}

.small {
  font-size: 8pt;
}

::selection {
  background: #3300ff;
  color: white;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
